/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import { Container, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import React, { Children } from 'react';
import { Views } from 'react-big-calendar';
import { connect, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  cancelledEventsDayCountSelector,
  dayTotalRevenueSelector,
  unblockedDaysSelector,
} from '../../redux/selectors/events.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { copyLocalDateToTimezone, isHoliday, whichHoliday } from '../../utils/dateUtils';

import { calendarTypeSelector } from '../../redux/selectors/calendar.selectors';
import { holidaysSelector, showRevenueSelector } from '../../redux/selectors/settings.selectors';
import { hasUserAccessSelector } from '../../redux/selectors/user.selectors';
import { CALENDAR_TYPE, CALENDAR_VIEWS, COLORS, ROLE_ACCESSES } from '../../utils/consts';
import formatCurrency from '../../utils/currency';
import ContainerItem from '../../utils/LayoutBuilders/ContainerItem';
import { LockIcon, UnlockIcon } from '../SVGIcons/SVGIcons';
import SimpleText from '../Text/SimpleText';
import CancelledEventsHeader from './CancelledEventsHeader';

const cancelledOrdersHeaderViews = [Views.DAY, Views.WEEK];
const holidayLabelHeaderViews = [Views.DAY];

// ColoredDateCellWrapper controls the style of each date cell in week view
const CalendarCellWrapper = ({ children, value, tz, formName, calendarType, showRevenue }) => {
  const calendarView = useSelector((state) => getFormValues(formName)(state).view ?? Views.WEEK);
  const holidays = useSelector(holidaysSelector);
  const dateString = value && copyLocalDateToTimezone(value, tz).startOf('day').format('YYYY-MM-DD');
  const cancelledEventsCount = useSelector((state) => cancelledEventsDayCountSelector(state, dateString));
  const showCancelledEvents =
    cancelledEventsCount?.cancelledEvents &&
    cancelledOrdersHeaderViews.includes(calendarView) &&
    calendarType === CALENDAR_TYPE.tci;
  const revenue = useSelector((state) => {
    if (!showRevenue) return 0;
    return dayTotalRevenueSelector(state, dateString, tz) ?? 0;
  });
  const unblockedDays = useSelector(unblockedDaysSelector);

  const ownCalendar = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const day = moment(value).format('YYYY-MM-DD');
  const showIcon =
    [0, 6].includes(value.getDay()) &&
    ownCalendar &&
    calendarType !== CALENDAR_TYPE.location &&
    [CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.WEEK].includes(calendarView);
  const isBlocked = !unblockedDays?.find((el) => el.date === day);

  const style = (chldr) => ({
    ...chldr.style,
    backgroundColor: 'white',
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '10px',
    display: 'flex',
    cursor: 'auto',
    justifyContent: 'space-between',
    ...(showIcon && { zIndex: 9 }),
  });

  const container = ({ child, ...rest }) => <Container {...(rest ?? {})}>{child}</Container>;
  const item = ({ child, ...rest }) => <ContainerItem {...(rest ?? {})}>{child}</ContainerItem>;

  const footer = () => (
    <>
      <div
        style={{
          bottom: 0,
          left: '5px',
          width: '100%',
          // display: 'inline-block',
          marginBottom: 0,
          position: 'absolute',
          whiteSpace: 'nowrap',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        {showRevenue && revenue > 0 && cancelledOrdersHeaderViews.includes(calendarView) ? (
          <Typography style={{ color: '#000', fontSize: showIcon ? 12 : 14, fontWeight: 'bold' }}>
            {formatCurrency(revenue)}
          </Typography>
        ) : (
          <></>
        )}
        {showCancelledEvents ? (
          <CancelledEventsHeader
            withLineBreak={calendarView !== Views.DAY}
            cancelledEventsInfo={cancelledEventsCount}
            formName={formName}
          />
        ) : undefined}
      </div>
    </>
  );

  const holidayLabel = () => <>{whichHoliday(value, holidays)}</>;

  const weekendView = (isLocked) =>
    container({
      style: {
        margin: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        cursor: 'pointer',
      },
      spacing: 2,
      child: [
        item({
          flex: 'auto',
          style: { padding: 5 },
          child: isLocked ? <UnlockIcon width={10} /> : <LockIcon width={10} />,
        }),
        item({
          flex: 'auto',
          child: SimpleText({
            txt: isLocked ? 'Unblock' : 'Block',
            fontSize: '0.75rem',
            fontWeight: 500,
            color: isLocked ? COLORS.CINTAS_GREEN : COLORS.CINTAS_BLUE,
          }),
        }),
      ],
    });

  const cellStyle = React.cloneElement(
    Children.only(children),
    { style: style(children) },
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        position: 'relative',
        width: '100%',
      }}
    >
      {container({
        style: {
          margin: 0,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
        },
        spacing: 0,
        child: [
          item({ flex: 'auto', style: { margin: 0, padding: 0 }, child: showIcon && weekendView(isBlocked) }),
          item({
            flex: 10,
            style: { marginLeft: 6, color: COLORS.CINTAS_RED, fontWeight: 'bold' },
            child: isHoliday(value, holidays) && holidayLabelHeaderViews.includes(calendarView) && holidayLabel(),
          }),
        ],
      })}
      {/* {value.getDay() === 6 && <UnblockIcon />} */}

      {footer()}
    </div>,
  );

  return cellStyle;
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
    calendarType: calendarTypeSelector(state),
    showRevenue: calendarTypeSelector(state) === CALENDAR_TYPE.location ? false : showRevenueSelector(state),
  })),
])(CalendarCellWrapper);

/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import _ from 'lodash';
import { change, Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import { Grid, MenuItem, Typography } from '@material-ui/core';

import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { useSelector } from 'react-redux';
import FASSelectField from '../../../../../Forms/CustomFormComponents/FASSelectField';
import StyledButton from '../../../../../Button/StyledButton';
import CommuncationTemplateText, {
  templateOptions,
} from '../../../../../../utils/consts/StaticText/CommunicationTemplateText';
import SimpleText from '../../../../../Text/SimpleText';
import * as OrdersActions from '../../../../../../redux/actions/orders.actions';
import * as ModalsActions from '../../../../../../redux/actions/modals.actions';
import { hasUserAccessSelector } from '../../../../../../redux/selectors/user.selectors';
import { COLORS, ROLE_ACCESSES, TIME_FORMAT } from '../../../../../../utils/consts';
import { timezoneSelector } from '../../../../../../redux/selectors/utils.selectors';
import CommunicationHistoryTable from './CommunicationHistoryTable';
import DateUtils from '../../../../../../utils/dateUtils';

const useStyles = makeStyles({
  button: {
    width: '200px',
    height: '60px',
    marginTop: '30px',
    marginInline: '30px',
  },
});

const fieldNames = {
  emailTemplate: 'emailTemplate',
  firstName: 'firstName',
  instructor: 'instructor',
  time: 'time',
};

export default function CommunicationsTab({
  dispatch,
  formName,
  order,
  updateOrderCommunicationHistory,
  subcontracted,
  tzOverride,
}) {
  const classes = useStyles();
  const [body, setBody] = useState('');
  const tz = useSelector((state) => tzOverride?.value ?? timezoneSelector(state));
  const [warning, setWarning] = useState();
  const [template, setTemplate] = useState(templateOptions[0]);
  const [confirmationURL, setConfirmationURL] = useState('');
  const confirmationToken = useRef('');
  const hasEditAccess = useSelector((state) =>
    hasUserAccessSelector(state, [subcontracted ? ROLE_ACCESSES.subcontractorEvents : ROLE_ACCESSES.editEvent]),
  );

  const sortHistory = (a, b) => {
    const aMom = moment.utc(a.date);
    const bMom = moment.utc(b.date);
    return aMom.isBefore(bMom) ? 1 : -1;
  };

  const [communicationHistory, setHistory] = useState((order?.communication_history ?? []).sort(sortHistory));

  useEffect(() => {
    dispatch(change(formName, fieldNames.emailTemplate, templateOptions[0]));
    handleTemplateChange(templateOptions[0]);
  }, []);

  useEffect(() => {
    if (confirmationURL !== '') {
      submitEmail();
    }
  }, [confirmationURL]);

  const fetchConfirmationURL = async () => {
    const url = await dispatch(OrdersActions.generateConfirmation(order?.id));
    setConfirmationURL(url);
    const searchQuery = (url ?? '').split('?')?.[1] ?? '';
    confirmationToken.current = (queryString.parse(searchQuery) ?? {}).token ?? '';
  };

  const addHistoryEntry = async () => {
    const newHistory = (await dispatch(OrdersActions.fetchOrderSummary(order?.id)))?.communication_history;
    const newHistorySorted = [...(newHistory ?? [])].sort(sortHistory);
    setHistory(newHistorySorted);
    updateOrderCommunicationHistory(newHistorySorted);
  };

  const submitEmail = async () => {
    try {
      setBody(buildEmailBody(template)); // refresh the template body
      await dispatch(
        OrdersActions.sendCommunication(
          order.id,
          order.contact ?? order.temporaryContact,
          template,
          confirmationToken.current,
          buildEmailBody(template, true),
        ),
      );
      dispatch(
        ModalsActions.showModal('SEND_COMMUNICATION_SUCCESS', {
          modalType: 'FAS_EVENT_DIALOG',
          modalProps: {
            bodyTextStyle: { fontSize: 18 },
            maxWidth: 'lg',
            cancelText: 'Continue',
            title: 'Message sent',
            content: (
              <Typography
                style={{
                  fontFamily: 'proxima-nova',
                  fontStyle: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  margin: 32,
                }}
              >
                Message successfully sent to customer.
              </Typography>
            ),
          },
        }),
      );
      addHistoryEntry();
    } catch (error) {
      dispatch(
        ModalsActions.showModal('SEND_COMMUNICATION_ERROR', {
          modalType: 'ERROR_ALERT',
          modalProps: {
            message: 'Something happened while sending this communication to the customer. Please, try again!',
          },
        }),
      );
    }
  };
  const formatCourseNames = (event) => {
    let courseNames = '';
    const courseArray = [];
    const courseCount = {};

    if (event) {
      event.forEach((element) => {
        courseArray.push({ title: element.title, type: element.courseObj.type });
      });

      courseArray.forEach((element) => {
        if (element.type === 'COURSE') {
          courseCount[element.title] = (courseCount[element.title] || 0) + 1;
        } else if (element.type === 'SKILL_CHECK') {
          courseCount[element.title] = courseCount[element.title] || 0;
        }
      });

      Object.entries(courseCount).forEach((element, index, array) => {
        if (array.length <= 1) {
          if (element[1] > 1) {
            courseNames += `${element[0]} (${element[1]})`;
          } else if (element[1] <= 1) {
            courseNames += `${element[0]}`;
          }
        } else if (array.length > 1) {
          if (index < array.length - 1) {
            if (element[1] > 1) {
              courseNames += `${element[0]} (${element[1]}), `;
            } else if (element[1] <= 1) {
              courseNames += `${element[0]}, `;
            }
          } else if (index === array.length - 1) {
            if (element[1] > 1) {
              courseNames += `and ${element[0]} (${element[1]})`;
            } else if (element[1] <= 1) {
              courseNames += `and ${element[0]}`;
            }
          }
        }
      });
    }

    return courseNames;
  };

  const buildEmailBody = (temp, withPointer) => {
    const text = CommuncationTemplateText[temp.id].body;
    let customertz;
    try {
      customertz =
        order.customertz ?? DateUtils.customerZipToTzLabel(order.customerObj?.postal_code, { defaultVal: tz });
    } catch (e) {
      customertz = tz;
    }
    const date = moment.utc((order?.events ?? [])[0]?.startTime).tz(customertz);
    const contact = order?.contact ?? order?.temporaryContact ?? {};
    return text
      .replaceAll(
        CommuncationTemplateText.injectionPattern.firstName,
        _.startCase((contact.FirstName ?? '--').toLowerCase()),
      )
      .replaceAll(CommuncationTemplateText.injectionPattern.courseName, formatCourseNames(order?.events ?? []) ?? '--')
      .replaceAll(
        CommuncationTemplateText.injectionPattern.signature,
        `${(order?.events ?? [])[0]?.instructor?.firstName} ${(order?.events ?? [])[0]?.instructor?.lastName}` ?? '--',
      )
      .replaceAll(CommuncationTemplateText.injectionPattern.month, date ? date.format('MMMM') : '--')
      .replaceAll(CommuncationTemplateText.injectionPattern.day, date.format('Do') ?? '--')
      .replaceAll(CommuncationTemplateText.injectionPattern.time, date.format(TIME_FORMAT) ?? '--')
      .replaceAll(
        CommuncationTemplateText.injectionPattern.tciEmail,
        (order?.events ?? [])[0]?.instructor?.email ?? '--',
      )
      .replaceAll(CommuncationTemplateText.injectionPattern.confirmationUrlPointer, withPointer ? '' : 'none')
      .replaceAll(CommuncationTemplateText.injectionPattern.confirmationUrl, confirmationURL);
  };

  const handleTemplateChange = (selection) => {
    if (selection.id === CommuncationTemplateText.updateSignature.id && order?.signature) {
      setWarning('This order has already been signed');
    } else {
      setWarning(undefined);
    }

    setBody(buildEmailBody(selection));
    setTemplate(selection);
  };

  const communicationHistoryTable = () => (
    <CommunicationHistoryTable
      wrapperStyle={{ paddingTop: 45, paddingBottom: 30 }}
      hideTitle={!hasEditAccess}
      data={communicationHistory}
    />
  );

  const communicationEmailsTemplates = () => (
    <>
      <Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <Field
            variant="outlined"
            placeholder="Select Template"
            id={fieldNames.emailTemplate}
            name={fieldNames.emailTemplate}
            component={FASSelectField}
            displayEmpty
            style={{ textAlign: 'start', borderRadius: 0 }}
            onChange={handleTemplateChange}
          >
            {templateOptions.map((t) => (
              <MenuItem key={t.id} value={t}>
                {t.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      {warning && (
        <Grid item>
          <SimpleText txt={warning ?? ''} style={{ color: COLORS.CINTAS_RED }} />
        </Grid>
      )}
      <Grid container spacing={5} direction="column" justifyContent="center" alignItems="flex-start">
        <Grid item>
          <div
            dangerouslySetInnerHTML={{ __html: body }}
            style={{
              paddingLeft: '100px',
              paddingRight: '100px',
              paddingTop: '50px',
              textAlign: 'left',
            }}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: '10vh' }}>
        <StyledButton
          className={classes.button}
          variant="contained"
          width="160px"
          disabled={!order?.events || warning}
          handleButton={fetchConfirmationURL}
          buttonContent="Send"
          color="primary"
        />
      </div>
    </>
  );

  return (
    <div
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16,
        width: '100%',
      }}
    >
      {hasEditAccess && communicationEmailsTemplates()}
      <div>{communicationHistoryTable()}</div>
    </div>
  );
}
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector, unregisterField } from 'redux-form';

import { hasUserAccessSelector } from '../../../../../../redux/selectors/user.selectors';
import { ROLE_ACCESSES } from '../../../../../../utils/consts';
import StyledButton from '../../../../../Button/StyledButton';
import FASFieldTitle from '../../../../../Forms/CustomFormComponents/FASFieldTitle';
import FASSelectField from '../../../../../Forms/CustomFormComponents/FASSelectField';
import MDTextInputField from '../../../../../Forms/FormComponents/MDTextInput/MDTextInputField';
import Container from '../../../../../LayoutBuilders/Container';
import ContainerItem from '../../../../../LayoutBuilders/ContainerItem';
import SimpleText from '../../../../../Text/SimpleText';

const fields = {
  tempContact: 'temporaryContact',
  tempContactFields: {
    first: 'FirstName',
    last: 'LastName',
    email: 'Email',
    phone: 'Phone',
  },
  customerContact: 'customerContact',
};

const fieldLabels = {
  [fields.tempContactFields.first]: 'First Name',
  [fields.tempContactFields.last]: 'Last Name',
  [fields.tempContactFields.email]: 'Email',
  [fields.tempContactFields.phone]: 'Phone',
};

const capitalize = (str) => {
  if (!str) return '';
  const words = str.split(' ');
  return words.map(_.capitalize).join(' ');
};

const AddContactDialog = (props) => {
  const dispatch = useDispatch();
  const { constomerContacts, disabled, customerContact, temporaryContact, formName } = props;

  const [insertTempContact, setInsertTempContact] = React.useState(false);
  const formValues = useSelector((state) => state.form?.AddEventDialog?.values ?? {});
  const customerSelected = formValues?.customer ?? {};
  const hasEditAccess = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.bookEvent]));

  const hasCustomer = Boolean(formValues.customer);

  React.useEffect(async () => {
    if (customerContact && (customerContact.SAPContactID ?? '').toLowerCase().trim() !== 'other') {
      const val = constomerContacts.find((c) => c.SAPContactID === customerContact.SAPContactID);
      dispatch(change(formName, fields.customerContact, val));
    } else if (temporaryContact) {
      await Promise.all([
        ...Object.values(fields.tempContactFields).map((field) =>
          dispatch(change(formName, `${fields.tempContact}.${field}`, temporaryContact[field])),
        ),
        dispatch(change(formName, fields.customerContact, undefined)),
      ]);
      setInsertTempContact(true);
    }
  }, []);

  React.useEffect(async () => {
    if (insertTempContact) {
      await Promise.all(
        Object.values(fields.tempContactFields).map((toUnregister) =>
          dispatch(unregisterField(formName, toUnregister)),
        ),
      );
      setInsertTempContact(false);
      dispatch(change(formName, fields.tempContact, undefined));
    }
  }, [customerSelected]);

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, ...rest }) => <ContainerItem {...(rest ?? {})}>{children}</ContainerItem>;

  const handleChange = async (value) => {
    if ((value.SAPContactID ?? '').toLowerCase().trim() === 'other') {
      await dispatch(unregisterField(formName, fields.customerContact));
      setInsertTempContact(true);
      dispatch(change(formName, fields.customerContact, null)); // using null instead of undefined because it didn't delete the customer contact
    } else {
      setInsertTempContact(false);
      dispatch(change(formName, fields.tempContact, null));
    }
  };

  const title = () => <FASFieldTitle title={`${insertTempContact ? 'Temporary' : 'Customer'} Contact`} />;

  const dropdown = () => (
    <Field
      variant="outlined"
      placeholder="Select a customer name"
      id="customerContact"
      name="customerContact"
      component={FASSelectField}
      onChange={handleChange}
      disabled={disabled || !hasCustomer || !hasEditAccess}
      defaultValue={{}}
      initiallyValidate={hasCustomer && !disabled}
    >
      {constomerContacts.map((contact, i) => (
        <MenuItem divider={i !== constomerContacts.length - 1} key={contact.SAPContactID} value={contact}>
          <SimpleText
            withSubtitle
            subtitleFontSize={10}
            subtitleStyle={{ paddingLeft: 2 }}
            txt={`${capitalize(contact.FirstName)} ${capitalize(contact.LastName)}\n${(contact.Phones?.length ? contact.Phones : [{}])[0].Phone ?? ''}${contact.Phones?.length && contact.Phones[0]?.Phone ? ' - ' : ''}${(contact.Email ?? '').toLowerCase()}`}
          />
        </MenuItem>
      ))}
    </Field>
  );

  const backToRegularContactsBtn = () => (
    <StyledButton
      variant="text"
      color="primary"
      style={{ fontSize: 12 }}
      buttonContent="select existing contact instead"
      disabled={_.isEmpty(
        (constomerContacts ?? []).filter((c) => (c.SAPContactID ?? '').toLowerCase().trim() !== 'other'),
      )}
      handleButton={async () => {
        await Promise.all(
          Object.values(fields.tempContactFields).map((toUnregister) =>
            dispatch(unregisterField(formName, toUnregister)),
          ),
        );
        setInsertTempContact(false);
        dispatch(change(formName, fields.tempContact, undefined));
      }}
    />
  );

  const inputField = ({ id, placeholder, type, label, required, width }) => (
    <Field
      id={id}
      name={id}
      component={MDTextInputField}
      size="small"
      type={type ?? 'text'}
      inputProps={{ width, style: { textAlign: 'start' } }}
      variant="outlined"
      required={required}
      disabled={!hasEditAccess || !hasCustomer}
      label={label}
      noBorderRadius
      noErrorLabel
      style={{ width }}
      placeholder={placeholder}
    />
  );

  const tempContactForm = () =>
    item({
      flex: 12,
      children: container({
        spacing: 3,
        children: [
          ...Object.values(fields.tempContactFields)
            .filter((f) => f !== fields.tempContactFields.phone)
            .map((field) =>
              item({
                flex: 6,
                children: inputField({
                  id: `${fields.tempContact}.${field}`,
                  label: fieldLabels[field],
                  required: field !== fields.tempContactFields.phone,
                  width: '100%',
                }),
              }),
            ),
          item({
            flex: 6,
            children: inputField({
              type: 'phone',
              id: `${fields.tempContact}.${fields.tempContactFields.phone}`,
              label: fields.tempContactFields.phone,
              width: '100%',
            }),
          }),
          item({
            flex: 12,
            children: backToRegularContactsBtn(),
          }),
        ],
      }),
    });

  return item({
    flex: 12,
    children: [
      item({ flex: 12, children: title() }),
      insertTempContact
        ? tempContactForm()
        : container({
            spacing: 3,
            children: [item({ flex: 10, children: dropdown() })],
          }),
    ],
  });
};

const selector = formValueSelector('AddEventDialog');
export default _.flow([
  connect((state) => {
    // can select values individually
    const customerContact = selector(state, fields.customerContact);
    const temporaryContact = selector(state, fields.tempContact);
    // or together as a group
    return {
      customerContact,
      temporaryContact,
    };
  }),
])(AddContactDialog);

const formatCurrency = (num, currency = 'USD') => {
  let curr = currency.toUpperCase();
  if (!['USD', 'CAD'].includes(curr)) {
    curr = 'USD';
  }
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: curr,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(num);
};

export default formatCurrency;

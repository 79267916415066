/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import { Box } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import NoAccountsSharpIcon from '@mui/icons-material/NoAccountsSharp';
import { COLORS, ROLE_ACCESSES } from '../../../utils/consts';
import StyledButton from '../../Button/StyledButton';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import ExpansionTile from '../../LayoutBuilders/ExpansionTile';
import SimpleText from '../../Text/SimpleText';
import ManageTCICerts from './ManageTCICerts';

import * as ModalsActions from '../../../redux/actions/modals.actions';
import { hasUserAccessSelector } from '../../../redux/selectors/user.selectors';

const fields = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'usrid_long',
  assignedLoc: 'zip_codes',
};

const OrgStuctureTile = ({ locations, entry, onCertAdded }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const myTciProfile = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile]));
  const shouldAllowExpansion = useSelector(
    (state) => hasUserAccessSelector(state, [ROLE_ACCESSES.viewTrainersProfile]) && !myTciProfile,
  );
  const name = useMemo(() => `${entry[fields.firstName]} ${entry[fields.lastName]}`);
  const defaultTruckNumber = useMemo(() => entry?.defaultTruckNumber ?? 'N/A', [entry]);
  let locationZips = (entry[fields.assignedLoc] ?? '').replaceAll(',', ', ');
  locationZips = locationZips.trim() !== '' ? locationZips : undefined;
  const canEditCertifications = useSelector((state) =>
    hasUserAccessSelector(state, [ROLE_ACCESSES.editTrainerProfile]),
  );
  // const tcis = useSelector((state) => state.users.data.reportingTcis ?? []);
  const certificationsData = useSelector((state) => state.certifications.data);

  const buildMultiLocationLabel = useCallback(() => {
    const tciLocations = Array.from(new Set((locations ?? []).map((l) => l.label ?? '')));
    return tciLocations.reduce((reduced, loc) => `${reduced}${reduced ? '\n' : ''}${loc}`, '');
  }, [locations]);

  const getChild = () => {
    if (shouldAllowExpansion) {
      return myTciProfile ? noGroupingChild() : isrmChild({ hideZips: true });
    }
    return <></>;
  };

  const text = (txt, style) => <SimpleText txt={txt} style={{ textAlign: 'start', ...(style ?? {}) }} />;

  const labelColumn = (top, bottom) => (
    <div style={{ width: '100%' }}>
      {text(_.startCase(top.toLowerCase()), { fontSize: 14, fontWeight: 'bold' })}
      {text(bottom, { fontSize: 15 })}
    </div>
  );

  const certificationsList = (certs) => {
    const display = _(certs)
      .map((id) => {
        const cert = _.find(certificationsData, { id });
        return cert?.title;
      })
      .sort()
      .value();
    return (
      <>
        {text('Certifications', { fontSize: 14, fontWeight: 'bold' })}
        {_.isEmpty(certs)
          ? text('No certifications assigned', { fontSize: 15 })
          : _.map(display, (c) => <div key={c}>{text(c, { fontSize: 15 })}</div>)}
      </>
    );
  };

  const buildEntry = () => (
    <Box style={{ padding: '0px 14px', width: '100%' }}>{labelColumn(name, entry[fields.email])}</Box>
  );

  const showCertsDialog = () => {
    const certificationsOptions = _(certificationsData)
      .filter((c) => _.includes(entry.certifications, c.id))
      .sortBy('title')
      .value();
    dispatch(change('TCICertsForm', 'certifications', certificationsOptions));
    dispatch(
      ModalsActions.showModal('MANAGE_TCI_CERTS', {
        modalType: 'FAS_EVENT_DIALOG',
        modalProps: {
          bodyTextStyle: { fontSize: 18 },
          hideCancel: true,
          disableBackdropClick: true,
          maxWidth: 'lg',
          title: 'MANAGE CERTIFICATIONS',
          content: <ManageTCICerts confirmText="Submit" onCertAdded={onCertAdded} uid={entry.uid} />,
        },
      }),
    );
  };

  const locationCell = ({ locLabel, flex }) => (
    <ContainerItem flex={flex}>{labelColumn('Location', locLabel ?? 'N/A')}</ContainerItem>
  );

  const isrmChild = useCallback(
    ({ hideZips, locationsRenderer }) => (
      <Container spacing={1}>
        {locationsRenderer
          ? locationsRenderer()
          : locationCell({
              flex: hideZips ? 12 : 6,
              locLabel: buildMultiLocationLabel(),
            })}
        {!hideZips && <ContainerItem flex={6}>{labelColumn('Postal Codes', locationZips ?? 'N/A')}</ContainerItem>}
        <ContainerItem flex={12}>{labelColumn('Default Truck Number', defaultTruckNumber)}</ContainerItem>
        <ContainerItem flex={12}>{labelColumn('Timezone', entry?.tz?.label ?? 'No timezone assigned')}</ContainerItem>
        <ContainerItem flex={12}>{labelColumn('Zip Code', entry?.zipcode ?? 'No zipcode assigned')}</ContainerItem>
        <ContainerItem flex={12}>{certificationsList(entry?.certifications ?? [])}</ContainerItem>
        {entry?.uid && canEditCertifications && (
          <ContainerItem flex={12}>
            <StyledButton
              variant="contained"
              color="primary"
              // width="160px"
              onClick={showCertsDialog}
              buttonContent="Certifications"
              startIcon={<AddIcon />}
            />
          </ContainerItem>
        )}
        {!entry?.uid && (
          <Container style={{ color: COLORS.CINTAS_RED }} alignItems="center">
            <NoAccountsSharpIcon />
            {text('Has not logged in yet', {
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'italic',
              paddingLeft: 5,
            })}
          </Container>
        )}
      </Container>
    ),
    [entry, locationZips, defaultTruckNumber, buildMultiLocationLabel, canEditCertifications, certificationsData],
  );

  const noGroupingChild = () =>
    isrmChild({
      hideZips: true,
      locationsRenderer: () =>
        locationCell({
          flex: 12,
          locLabel: buildMultiLocationLabel(),
        }),
    });

  return (
    <ExpansionTile
      key={entry.uid}
      header={buildEntry()}
      headerFlex="100%"
      noExpanding={!shouldAllowExpansion}
      style={{
        padding: 0,
        margin: 0,
        textAlign: 'start',
        width: '100%',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
      onExpansionChange={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      {getChild()}
    </ExpansionTile>
  );
};

export default memo(OrgStuctureTile);

/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import _ from 'lodash';
import React, { Suspense, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as CertificationsActions from '../../../redux/actions/certifications.actions';
import { fetchOrgStructure, getTCIs } from '../../../redux/actions/users.actions';
import { hasUserAccessSelector, userInfoSelector } from '../../../redux/selectors/user.selectors';
import { COLORS, ROLE_ACCESSES } from '../../../utils/consts';
import Container from '../../LayoutBuilders/Container';
import ContainerItem from '../../LayoutBuilders/ContainerItem';
import Spinner from '../../SpinnerOverlay/Spinner';
import UserAccountCard from '../UserAccountCard';
import OrgStuctureTile from './OrgStuctureTile';

const UserOrgStructure = ({ user: { role }, minHeight }) => {
  const dispatch = useDispatch();
  const myTciProfile = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.tciProfile]));
  const hasOwnCalendar = useSelector((state) => hasUserAccessSelector(state, [ROLE_ACCESSES.ownCalendar]));
  const [loading, setLoading] = React.useState(true);
  const [orgStructure, setOrgStucture] = React.useState([]);
  const [tcimLocationMapping, setTcimLocationMapping] = React.useState({});

  React.useEffect(async () => {
    await loadOrgStructure(true);
  }, [role]);

  const loadOrgStructure = useCallback(
    async (initialLoad) => {
      setLoading(true);
      if (initialLoad) {
        await dispatch(getTCIs());
        await dispatch(CertificationsActions.fetchCertifications());
      }
      const resp = await dispatch(fetchOrgStructure());
      const dataUniq = _.uniqBy(resp, (e) => e.pernr); // (!myTciProfile) ? `${e.pernr}${e.location_id}` :
      const data = _.filter(dataUniq, (e) => e.usrid_long);
      setOrgStucture(data);
      if (!myTciProfile) {
        setTcimLocationMapping(mapToLocation(data));
      }
      setLoading(false);
    },
    [myTciProfile],
  );

  const onCertAdded = useCallback(
    (uid, newCerts) => {
      const newOrg = orgStructure.map((entry) =>
        entry?.uid === uid ? { ...entry, certifications: newCerts ?? [] } : entry,
      );
      setOrgStucture(newOrg);
      if (!myTciProfile) {
        const newMapping = mapToLocation(newOrg);
        setTcimLocationMapping(newMapping);
      }
    },
    [orgStructure],
  );

  const mapToLocation = (data) => {
    const mapped = {};
    (data ?? []).forEach((d) => {
      (d.locations ?? []).forEach((dLoc) => {
        const { loc_id, loc_description } = dLoc;
        mapped[loc_id] = {
          data: [...((mapped[loc_id] ?? {})?.data ?? []), d],
          location: { id: loc_id, label: loc_description ?? '' },
        };
      });
    });
    return mapped;
  };

  const getTitle = () => {
    if (hasOwnCalendar) return 'Managers';
    return 'Team Members';
  };

  const container = ({ children, ...rest }) => <Container {...(rest ?? {})}>{children}</Container>;
  const item = ({ children, style, ...rest }) => (
    <ContainerItem
      {...(rest ?? {})}
      style={{
        textAlign: 'center',
        ...(style ?? {}),
        wordWrap: 'break-word',
        wordBreak: 'break-all',
      }}
    >
      {children}
    </ContainerItem>
  );

  const structureListView = useCallback(
    () => (
      <div style={{ overflow: 'scroll', width: '100%' }}>
        {orgStructure.map((entry) => (
          <OrgStuctureTile
            entry={entry}
            locations={(entry?.locations ?? []).map((loc) => ({ id: loc.loc_id, label: loc.loc_description }))}
            onCertAdded={onCertAdded}
          />
        ))}
      </div>
    ),
    [orgStructure, onCertAdded],
  );

  const tcimLocHeader = (locationData) => (
    <div
      style={{
        width: '100%',
        backgroundImage: `linear-gradient(to bottom right, ${COLORS.CINTAS_BLUE}, ${COLORS.CINTAS_BLUE_GRADIENT})`,
        textAlign: 'start',
        color: COLORS.CINTAS_WHITE,
        fontWeight: 'bold',
        fontSize: 14,
        padding: '8px 12px',
      }}
    >
      {`${locationData.label}`}
    </div>
  );

  const tcimStructureListView = useCallback(
    () =>
      Object.keys(tcimLocationMapping).map((e) => (
        <>
          {tcimLocHeader(tcimLocationMapping[e].location)}
          {_.map(tcimLocationMapping[e].data, (entry) => (
            <OrgStuctureTile entry={entry} locations={[tcimLocationMapping[e].location]} onCertAdded={onCertAdded} />
          ))}
        </>
      )),
    [tcimLocationMapping, onCertAdded],
  );

  const spinner = () => (
    <div style={{ width: '100%', marginTop: 10 }}>
      <Spinner />
    </div>
  );

  return (
    <UserAccountCard title={getTitle()} minHeight={minHeight} maxHeight={minHeight}>
      <div
        style={{
          height: minHeight - 2, // -2 for the bottom border of the box
          overflow: 'scroll',
          padding: loading ? '45%' : 0,
          width: '100%',
        }}
      >
        {container({
          style: {
            justifyContent: 'start',
            width: '100%',
            alignItems: 'stretch',
          },
          spacing: 0,
          children: loading ? (
            item({ flex: 12, style: { maringLeft: 50 }, children: spinner() })
          ) : (
            <Suspense fallback={item({ flex: 12, style: { maringLeft: 50 }, children: spinner() })}>
              {myTciProfile ? tcimStructureListView() : structureListView()}
            </Suspense>
          ),
        })}
      </div>
    </UserAccountCard>
  );
};

export default _.flow([
  connect((state) => ({
    user: userInfoSelector(state) ?? {},
  })),
])(UserOrgStructure);

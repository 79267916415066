import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { dayTotalRevenueSelector } from '../../redux/selectors/events.selectors';
import { timezoneSelector } from '../../redux/selectors/utils.selectors';
import { ccyFormat } from '../../utils/helpers';

const DayRevenueLabel = ({ date, tz, alignment }) => {
  const revenue = useSelector((state) => (!date ? 0.0 : dayTotalRevenueSelector(state, date, tz)));

  return revenue ? (
    <Typography
      style={{
        color: '#000',
        fontWeight: 'bold',
        textAlign: alignment ?? 'start',
        fontSize: 14,
        paddingTop: '5%',
      }}
    >
      {ccyFormat(revenue)}
    </Typography>
  ) : (
    <></>
  );
};

export default _.flow([
  connect((state) => ({
    tz: timezoneSelector(state),
  })),
])(DayRevenueLabel);
